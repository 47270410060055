export const darkTheme = {
  bg: "#1C1C27",
  bgLight: "#1C1E27",
  primary: "#854CE6",
  text_primary: "#F2F3F4",
  text_secondary: "#b1b2b3",
  card: "#171721",
  card_light: "#191924",
  button: "#854CE6",
  white: "#FFFFFF",
  black: "#000000",
  copyright: "#FFFFFF",
  navbar: "#191924",
  logo: "#854CE6",
  navitems: "#FFFFFF",
};

export const lightTheme = {
  bg: "#F2F2F2",
  bgLight: "#f0f0f0",
  primary: "#be1adb",
  text_primary: "#111111",
  text_secondary: "#48494a",
  card: "#F4F4F4",
  card_light: "#F2F2F2",
  button: "#5c5b5b",
  white: "#FFFFFF",
  black: "#000000",
  copyright: "#000000",
  navbar: "#F2F2F2",
  logo: "#be1adb",
  navitems: "#be1adb",
};
